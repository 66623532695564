<template>
  <div>
    <div class="row mt-3">
      <div class="col-6 mx-auto text-center">
        <div class="bg-light rounded p-2 d-flex shadow-inset">
          <div
                  @click="period='monthly'"
                  class="cursor-pointer rounded w-50"
                  :class="period==='monthly' ? 'bg-primary text-white' : 'bg-light'">
            Monthly
          </div>
          <div
                  @click="period='quarterly'"
                  class="cursor-pointer rounded w-50"
                  :class="period==='quarterly' ? 'bg-primary text-white' : 'bg-light'">
            Quarterly
          </div>
          <div
                  @click="period='annually'"
                  class="cursor-pointer rounded w-50"
                  :class="period==='annually' ? 'bg-primary text-white' : 'bg-light'">
            Annually
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4"
           v-for="product in products"
           :key="product.id">
        <div class="pricing-table card-deck text-center" style="border-radius: 10px">
          <pricing-card :user="user"
                        :product="product"
                        :period="period"
          ></pricing-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingCard from "./PricingCard";
export default {
  components: {PricingCard},
  props: {
    user: {},
    path: {},
    period: {
      default: 'monthly',
    }
  },
  data() {
    return {
      products: [],
    };
  },
  methods: {
    fetchBillableProducts() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-billable-products")
          .then(({ data }) => {
            this.products = data;
          });
    }
  },
  mounted() {
    this.fetchBillableProducts();
  }
};
</script>

<style>
</style>
