<template>
  <div class="card my-4 box-shadow">
    <i v-if="product.hierarchy >= 3" class="fa fa-medal fa-2x text-white" style="position: absolute; top: 0px; right: 10px;"></i>
    <div class="card-img-top p-2 pt-3 text-center"
         v-if="user"
         :class="getClassForProduct(product)">
      <h4 :class="product.hierarchy >= 3 ? 'fw-bold' : ''">{{ product.title }}</h4>
      <p class="mb-2">
        <span class="badge bg-info me-1">
          {{ product.client_limit > 0 ? product.client_limit : 'Unlimited' }}
        </span>
        clients
      </p>
      <p class="mb-2">
        <span class="badge bg-info me-1">
          {{ product.storage_limit_gb > 0 ? product.storage_limit_gb + 'GB' : 'Unlimited' }}
        </span>
        storage
      </p>
    </div>
    <div class="card-body text-center">
      <h2 class="card-title pricing-card-title mb-2">
        £{{selectedPlan.effective_monthly_price_display}}
        <small class="text-muted">/ month</small>
      </h2>
      <h6 v-if="period !== 'monthly'" class="text-primary font-italic">
        Billed {{period}}
      </h6>
      <h6 v-if="period !== 'monthly'" class="text-primary font-italic">
        Save £{{ periodAnnualSaving | formatCurrency}} annually!
      </h6>
      <div v-if="!hideCta">
        <a class="fw-bold" href="https://swandoola.com/#pricing">See All Features</a>
        <hr />
      </div>
      <button type="button" class="btn btn-lg btn-block"
              v-if="!hideCta"
              :class="getButtonClassForProduct(product)"
              disabled>
        <span v-if="user.clinic.billing_subscription.billing_product.title === product.title">
          <i class="fas fa-check-circle me-1"></i>My Plan
        </span>
        <span v-else-if="user.clinic.billing_subscription.billing_product.hierarchy < product.hierarchy">
          <i class="fas fa-arrow-alt-up me-1"></i>Upgrade
        </span>
        <span v-else-if="user.clinic.billing_subscription.billing_product.hierarchy > product.hierarchy">
          <i class="fas fa-arrow-alt-down me-1"></i>Downgrade
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "product", "period", "hideCta"],
  data() {
    return {
    };
  },
  methods: {
    goToUpgrade(plan) {
      window.location = "/billing/upgrade/" + plan + '?period=' + this.period;
    },
    getClassForProduct(product) {
      switch (product.hierarchy) {
        case 1:
          return 'bg-light';
        case 2:
          return 'alert-primary';
        case 3:
          return 'bg-primary text-white';
      }
    },
    getButtonClassForProduct(product) {
      switch (product.hierarchy) {
        case 1:
          return 'btn-light';
        case 2:
          return 'alert-primary';
        case 3:
          return 'btn-primary';
      }
    },
  },
  computed: {
    selectedPlan() {
      return this.product.billing_plans.filter((p) => {
        return (
            p.billing_period === this.period && p.allow_new_signups
        );
      })[0];
    },
    productMonthlyPrice() {
      return this.product.billing_plans.filter((p) => {
        return (
            p.billing_period === 'monthly' && p.allow_new_signups
        );
      })[0].price;
    },
    periodAnnualSaving() {
      return (this.productMonthlyPrice - this.selectedPlan.effective_monthly_price_display) * 12;
    }
  },
  filters: {
    formatCurrency(val) {
      return val.toFixed(2);
    },
  },
  mounted() {
  }
};
</script>

<style>
</style>
