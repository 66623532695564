<template>
  <div class="rounded p-2 d-flex shadow-inset bg-white">
    <div
        class="cursor-pointer rounded w-50"
        :class="period==='monthly' ? 'bg-primary text-white' : 'bg-light'"
        @click="setPeriod('monthly')">
      Monthly
    </div>
    <div
        class="cursor-pointer rounded w-50"
        :class="period==='quarterly' ? 'bg-primary text-white' : 'bg-light'"
        @click="setPeriod('quarterly')">
      Quarterly
    </div>
    <div
        class="cursor-pointer rounded w-50"
        :class="period==='annually' ? 'bg-primary text-white' : 'bg-light'"
        @click="setPeriod('annually')">
      Annually
    </div>
  </div>
</template>

<script>

export default {
  props: ["user"],
  data() {
    return {
      period: null
    }
  },
  components: {

  },
  methods: {
    setPeriod(period) {
      this.period = period;
      this.$emit("updated", period);
    }
  },
  computed: {
  },
  filters: {
  },
  mounted() {
    if (this.$route.query.period) {
      this.setPeriod(this.$route.query.period);
    } else {
      this.setPeriod(this.user.clinic.billing_subscription.billing_period);
    }
  }
};
</script>

<style>
</style>