<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-body mb-4 pt-0">
            <div class="row">
              <div class="col">
                <p class="alert alert-primary mt-3 mb-0">
                  <i class="far fa-info-circle me-1"></i>
                  Current Plan: <b>{{user.clinic.billing_subscription.billing_product.title}}</b>
                </p>
                <pricing-table :user="user" :path="path"></pricing-table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PricingTable from "../partials/PricingTable.vue";
import BillingNav from "../partials/BillingNav";
export default {
  props: [],
  components: {
    BillingNav,
    PricingTable
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
};
</script>

<style>
</style>