<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body pt-3">
            <h4>Update Subscription</h4>
            <p class="alert alert-primary" v-if="trialDays > 0">
              {{trialDays}} day free trial! You will not be billed until {{cycleStartAfterTrial | formatDate}}, and can cancel at any time.
            </p>
            <div class="card bg-light mb-3">
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-6 mx-auto text-center">
                    <period-selector @updated="periodSelected" :user="user"></period-selector>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4 mx-auto">
                    <pricing-card
                        :product="product"
                        :period="period"
                        :user="user"
                        :hide-cta="true"></pricing-card>
                  </div>
                </div>
              </div>
            </div>


            <div class="row mb-3">
              <div class="col">
                <h4>Payment Method</h4>

                <div class="card bg-light">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <select class="form-control"
                                :class="paymentMethod === 'newCard' ? 'mb-3' : ''"
                                v-model="paymentMethod">
                          <option selected disabled :value="null">Select a Payment Method</option>
                          <option v-for="method in user.clinic.billing_payment_methods"
                                  :value="method.id">
                            {{method.card_brand.toUpperCase()}} - xxxx-xxxx-xxxx-{{method.card_last_four}} ({{method.exp_month | padMonth}}/{{method.exp_year}})
                          </option>
                          <option :value="'newCard'">Add New Card</option>
                        </select>

                        <div class="card border-0" :class="paymentMethod !== 'newCard' ? 'd-none' : ''">
                          <div class="card-body" v-if="loading">
                            <span class="h4" >Loading...</span>
                          </div>
                          <div class="card-body bg-silver border-primary rounded " :class="loading ? 'd-none' : ''">
                            <form role="form">
                              <!-- Cardholder's Name -->
                              <div class="row mb-2">
                                <div class="col mx-auto">
                                  <label>Cardholder's Name</label>
                                  <input type="text" class="form-control" name="name" v-model="name" />
                                </div>
                              </div>

                              <!-- Card Details -->
                              <div class="row mb-1">
                                <div class="col mx-auto">
                                  <label>Card Details</label>
                                  <div id="payment-card-element"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>




            <div class="row mb-3" v-if="selectedPlan">
              <div class="col">
                <h4>Coupon Discounts</h4>
                <div class="card bg-light">
                  <div class="card-body">
                    <div v-if="billingSubscription.coupons.length === 0">
                      <p class="alert alert-primary">
                        <i class="fa fa-info-circle"></i>
                        If you have a coupon code, enter it below and click 'Apply Coupon' to receive your discount.
                      </p>
                      <div class="row">
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Enter a coupon code..." v-model="couponCode"/>
                        </div>
                        <div class="col">
                          <button class="btn btn-primary" :disabled="!couponCode" @click="applyCoupon">Apply Coupon</button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row">
                        <div class="col-4" v-for="coupon in billingSubscription.coupons">
                          <div class="card">
                            <div class="card-img-top bg-primary p-2 text-center text-white">
                              <p class="fw-bold">{{coupon.code}}</p>
                            </div>
                            <div class="card-body">
                              <div class="card-text text-center">
                                <p>
                                  <span v-if="coupon.type === 'percentage'">{{coupon.discount}}% off</span>
                                  <span v-if="coupon.type === 'fixed_amount'">£{{coupon.discount}} off</span>
                                  <span v-if="coupon.duration === 'forever'"> forever</span>
                                  <span v-if="coupon.duration === 'months'"> for {{coupon.num_months}} month</span><span v-if="coupon.num_months > 1">s</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="row" v-if="selectedPlan && invoiceBlueprint">
              <div class="col">
                <h4>Confirmation</h4>
                <p class="alert alert-primary" v-if="trialDays > 0">
                  {{trialDays}} day free trial! You will not be billed until {{cycleStartAfterTrial | formatDate}}, and can cancel at any time.
                </p>
                <upcoming-invoice :invoice="invoiceBlueprint" class="mx-2"></upcoming-invoice>
                <button
                    type="submit"
                    class="btn btn-primary mt-auto float-end"
                    @click.prevent="processPayment"
                    :disabled="busy || !paymentMethod"
                >
                  <busy :visible="busy" button>Processing Payment</busy>
                  <span v-if="!busy && isDowngrade">
                    Downgrade Subscription
                  </span>
                  <span v-else-if="!busy">
                    Upgrade Subscription
                  </span>
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BillingNav from "../partials/BillingNav";
import PricingCard from "../partials/PricingCard";
import PeriodSelector from "./upgrade-partials/PeriodSelector";
import UpcomingInvoice from "./subscription-partials/UpcomingInvoice";
export default {
  props: [],
  data() {
    return {
      loading: true,
      busy: false,
      cardElement: null,
      stripe_key: null,
      stripe: null,
      name: '',
      paymentMethod: null,
      selectedPlan: null,
      invoiceBlueprint: null,
      isDowngrade: null,
      period: null,
      couponCode: null,
      approvedCoupon: null,
      product: null,
    };
  },
  watch: {
    selectedPlan: function(val) {
      if (val) {
        this.fetchInvoiceBlueprint();
      } else {
        this.invoiceBlueprint = null;
        this.invoiceTotal = 0;
      }
    },
    product(val) {
      console.log(val);
      if (this.product && this.product['billing_plans']){
        this.product.billing_plans.forEach(bp => {
          if (bp.user_role === 'owner' && bp.billing_period === this.billingSubscription.billing_period){
            this.selectPlan(bp);
          }
        });
      }
    }
  },
  methods: {
    periodSelected(data) {
      this.period = data;
      if (this.product.billing_plans){
        this.selectedPlan = this.product.billing_plans.filter(function (p) {
          return p.user_role === 'owner' && p.billing_period === data && p.allow_new_signups
        })[0];
      }
    },
    processPayment() {
      this.busy = true;
      if (this.paymentMethod === 'newCard'){
        this.processNewCardPayment();
      } else {
        this.processCardPayment(this.paymentMethod, false);
      }
    },
    processCardPayment(paymentMethod, paymentMethodIsNew) {
      this.$axios.post(process.env.VUE_APP_API_URL + "/billing/payment-card", {
        name_on_card: this.name,
        billing_plan_id: this.selectedPlan.id,
        payment_method: paymentMethod,
        payment_method_is_new: paymentMethodIsNew,
        coupon_id: this.approvedCoupon ? this.approvedCoupon.id : null,
      }).then(({ data }) => {
        this.$EventBus.$emit("refreshUser");
        this.$EventBus.$emit("alert", data);
        const invoice_id = data.stripe_invoice_id;
        if (data.state === 'action_required'){
          const vm = this;
          this.stripe.confirmCardPayment(data.client_secret).then(function(result) {
            if (result.error) {
              // Display error.message in your UI.
              vm.busy = false;
              this.$EventBus.$emit("alert", {'message': result.error.message, 'color': 'danger'});
              // cancel invoice and revert subscription change as payment failed
              this.$axios.post(process.env.VUE_APP_API_URL + "/billing/payment-sca-failed", {
                invoice_id: invoice_id,
                original_plan_id: vm.billingSubscription.billing_plan.stripe_plan_id
              });
            } else {
              vm.busy = false;
              this.$EventBus.$emit("alert", {'message': "Payment successful!"});
              setTimeout(() => {
                this.$router.push({ name: 'BillingSubscription' });
              }, 2000);
            }
          });
        } else {
          if (data.color !== 'danger'){
            setTimeout(() => {
              this.$router.push({ name: 'BillingSubscription' });
            }, 2000);
          } else {
            this.busy = false;
          }
        }
      }).catch(function( error) {
          this.$EventBus.$emit("alert", {
              'color': "danger",
              'message': "Something went wrong - Payment has not been processed."
          });
          console.log(error);
      });
    },
    processNewCardPayment() {
      this.stripe.createPaymentMethod(
      {
        type: 'card',
        card: this.cardElement,
        billing_details: {
          name: this.name
        }
      }).then(result => {
        if (result.error) {
          self.hasCardErrors = true;
          this.busy = false;
        } else {
          this.processCardPayment(result.paymentMethod, true);
        }
      });
    },
    fetchInvoiceBlueprint() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/billing/invoice-blueprint", {
        billing_plan_id: this.selectedPlan.id,
        coupon_id: this.approvedCoupon ? this.approvedCoupon.id : null,
      })
      .then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.invoiceBlueprint = data.invoice;
      });
    },
    applyCoupon() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/billing/validate-coupon", {
        coupon_code: this.couponCode,
      })
      .then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        if (data.coupon){
          this.approvedCoupon = data.coupon;
          this.fetchInvoiceBlueprint();
        }
      });
    },
    selectPlan(billing_plan){
      this.period=billing_plan.billing_period;
      this.selectedPlan = billing_plan;
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        // hideIcon: true,
        // hidePostalCode: true,
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4"
            },
            fontFamily:
                    'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px"
          }
        }
      });

      card.mount(container);
      this.loading = false;
      return card;
    },
    fetchStripePublicKey() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/stripe-pk")
          .then(({ data }) => {
            this.stripe_key = data;
            this.stripe = Stripe(this.stripe_key);
          });
    },
    fetchProduct() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/billing/products/" + this.$route.params.product)
          .then(({ data }) => {
            this.product = JSON.parse(JSON.stringify(data));
            this.loading = false;
          });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    billingSubscription(){
      return this.user.clinic.billing_subscription;
    },
    invoiceTotal(){
      return this.invoiceBlueprint.amount_due;
    },
    trialDays(){
      if (this.billingSubscription && this.product){
        //coupon takes priority, otherwise, if free -> platinum they get 14 days
        if (this.approvedCoupon && this.approvedCoupon.trial_days > 0){
          return this.approvedCoupon.trial_days;
        } else {
          if (this.billingSubscription.billing_product.hierarchy === 0 && this.product.hierarchy >= 3){
            return 14;
          }
        }
      }

      return 0;
    },
    cycleStartAfterTrial(){
      return moment().add(this.trialDays, 'd');
    },
  },
  mounted() {
    this.fetchStripePublicKey();
    this.fetchProduct().then(() => {
      this.isDowngrade = this.user.clinic.billing_subscription.billing_product.hierarchy > this.product.hierarchy;
      this.fetchInvoiceBlueprint();
      this.cardElement = this.createCardElement("#payment-card-element");
    });

  },
  components: {
    UpcomingInvoice,
    PeriodSelector,
    PricingCard,
    BillingNav
  },
  filters: {
    formatUnixDate(date) {
      return moment.unix(date).format("LL");
    },
    formatDate(date){
      return moment(date).format("LL");
    },
    padMonth(month) {
      if (parseInt(month) < 10){
        return "0" + month;
      } else {
        return month;
      }
    },
  }
}
</script>

<style>
</style>
